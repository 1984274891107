<template>
   <div>
    <b-modal
      id="assetEditModal" 
      header-class="pb-2"
      size="xl"
      v-model="showModal">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Актив
                <small class="text-500">основные данные</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2 px-1">
        <b-col class="col-6">
          <b-form-group
            label="Как называется?"
            labele-for="directCostTitle" 
            :invalid-feedback="invalidFeedback"
            :state="titleState">
            <b-form-input 
              id="direcCostTitle"
              v-model="sectionData.title"
              :state="titleState">
            </b-form-input>
            <b-form-radio-group 
              id="radio-group-2"
              class="mt-1" 
              v-model="sectionData.type"
              :disabled="sectionData.title < 4"
              name="radio-component">
              <b-row>
                <b-col sm="3">
                  <b-form-radio class="m-0" 
                    :value="24" 
                    @input="onChangeType">
                    <span class="fs--2">Мебель и т.п.</span>
                  </b-form-radio>
                </b-col>
                <b-col sm="4">
                  <b-form-radio class="m-0 pl-0" 
                    :value="60"
                    @input="onChangeType">
                    <span class="fs--2">Машины и оборудование</span> 
                  </b-form-radio>
                </b-col>
                <b-col sm="5">
                  <b-form-radio class="m-0 pl-0" 
                    :value="120"
                    @input="onChangeType">
                    <span class="fs--2">Здания, сооружения и НМА</span>
                  </b-form-radio>
                </b-col>
                <b-col sm="12">
                  <b-alert v-if="titleState"
                    class="mt-0 p-1 fs--1 mb-0"
                    :show="true"
                    variant="light">
                    Аммортизация по данному типу активов - {{sectionData.type}} мес. <br> 
                    <small>* - амортизация равномерно отражается в "Отчете о прибылях и убытках" вне зависимости от сроков оплаты</small>
                  </b-alert>
                </b-col>
              </b-row>
            </b-form-radio-group>           
          </b-form-group>
        </b-col>
        <b-col class="col-6 mt-2 mb-3">
          <p class="fs--1">
            Просто сосредоточьтесь на описании приобретаемого актива, 
            вариантом которого могут быть: оборудование, транспортные средства, мебель или интеллектуальная собственность.
          </p>
          <p class="fs--1">
            Данные по оплате отразятся только в "Отчете о движении денежных средств" и не скажутся на прибыльности компании. 
            Если для покупки актива привлекаются заемные средства, внесите соотвествующий займ в разделе "Финансирование".
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="d-flex align-items-end">
          <h6 class="font-proxima fs-0 mb-0">
            Информация по платежам за приобретаемый актив.  
          </h6>
        </b-col>
        <b-col sm="6">
          <b-row>
            <b-col sm="4">
              <strong class="fs--2">
                Стоимость актива:
              </strong>
              <b-input-group
                size="sm" append="₽"> 
                <b-form-input 
                  v-model="sectionData.assetValue" 
                  placeholder="-"
                  class="form-control text-right"
                  type="number" 
                  :disabled="!titleState"
                  @update="onChangeValue" 
                  @change="calculatePayments" />
              </b-input-group>  
            </b-col>
            <b-col sm="4">
              <strong class="fs--2">
                Дата приобретения:
              </strong>
              <custom-dropdown
                v-model="dates"
                :selected="sectionData.startDate"
                :disabled="!titleState"
                :defaultItem="sectionData.startDate ? sectionData.startDate.periodIndex : null"
                placeholder="---" 
                size="sm" >
              </custom-dropdown>
            </b-col>
            <b-col sm="4">
              <strong class="fs--2">
                Тип платежа:
              </strong>
              <custom-dropdown
                v-model="assetPaymentOptions"
                :selected="sectionData.paymentType"
                :defaultItem="sectionData.paymentType ? sectionData.paymentType.id : null"
                placeholder="---"
                :disabled="!titleState"
                :stretch="false" 
                size="sm" >
              </custom-dropdown> 
            </b-col>
          </b-row>
        </b-col>
      </b-row>  
      <b-row class="mt-2">  
        <b-col sm="12">
          <wizard-section 
            :data="sectionData.values" 
            unitsTitle="рублей"
            titleHeight="2rem"
            :defaultMax="100000"
            :readonly="dataReadonly"
            :decimals="0">
          </wizard-section>
        </b-col>
      </b-row>


      <template v-slot:modal-footer>

        <div class="w-100">
          <div class="btn btn-outline-dark delete-btn ml-2 px-2 py-0 float-left"
            @click="deleteItem">
            <i class="far fa-trash-alt mr-2 py-0"></i>
            <span class="font-proxima font-weight-light">
              Удалить
            </span>
          </div>
          
          <b-button 
            v-if="loadData"
            class="float-right"
            variant="outline-success"
            size="sm"
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button
            v-if="!loadData"
            variant="outline-success"
            size="sm"
            class="float-right"
            :disabled="!titleState"
            @click="saveData">
            Готово!
          </b-button>
        </div>

      </template>
      
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

import CustomDropdown from '../widgets/CustomDropdown'
import WizardSection from '../widgets/Modals/IncomeCreate/WizardSection'

import CFCategories from '../../../store/CFCategories'


export default {
  data: () => ({
    showModal: false,
    loadData: false,
    dataReadonly : true
  }),

  components: {
    CustomDropdown,
    WizardSection,
  },

  computed: {
    bplanStartYear() {
      return this.$store.getters.getActiveBPlan.startYear;
    },

    sectionData() {
      var data = this.$store.getters.getAssetToEdit
      if (data) {
        return data
      } else {
        return {title: '', titleId: null}
      }
      
    },
    
    dates: {
      get: function() {
        var dateValues = []
        var startDay = moment(`${this.bplanStartYear}-01-01`)
        
        for (var i=0; i < 36; i++) {
          const dateToSelect = moment(startDay).add(i, 'months')
          dateValues.push({
            value: dateToSelect.format(), 
            title: dateToSelect.format('MMMM YYYY'), 
            periodIndex: i
          })
        }

        return dateValues
      },
      
      set: function(value) {
        if (value) {
          console.log(value)
          this.sectionData.startDate = value
          this.calculatePayments()
        }
      } 
    },

    assetPaymentOptions: {
      get: function() {
        return [
          { 
            id: 0, 
            title: 'Разовый платеж',
          },
          { 
            id: 1, 
            title: 'Равномерно' 
          },
          { 
            id: 2, 
            title: 'До конца года'
          },
          { 
            id: 3, 
            title: 'В течении 12 мес.'
          },
          { 
            id: 4, 
            title: 'В течении 24 мес.'
          },
          { 
            id: 5, 
            title: 'В течении 36 мес.'
          },
          { 
            id: 6, 
            title: 'Указать самому'
          },
        ]
      },
      set: function(value) {
        if (value) {
          this.sectionData.paymentType = value
          this.calculatePayments()
        } 
      }
    },

    CFCategories: {
      get: function() {
        var allCategories = []

        allCategories = allCategories.concat(CFCategories.direct)
        allCategories = allCategories.concat(CFCategories.labor)
        allCategories = allCategories.concat(CFCategories.commercial)
        allCategories = allCategories.concat(CFCategories.common)

        return allCategories
      },
      set: function(value) {
         if (value) {
          this.selectedCFCategory = value
        } else {
          this.selectedCFCategory = null
        }
      }
    },

    titleState() {
      if (this.sectionData.title.length > 3 && this.sectionData.type) {
        return  true
      } else {
        return false
      }
    },

    invalidFeedback() {
      if (!this.sectionData.title) {
        return 'Пожалуйства, введине название...'
      } else if (this.sectionData.title.length < 4) {
        return 'Название должно быть не менее 4-х символов'
      } else if (!this.sectionData.type) {
        return 'Пожалуйства, выберите тип актива...'
      } else if (this.sectionData.title.length >= 4 && this.sectionData.type) {
        return ''
      } else {
        return ''
      }
    },
  },

  methods: {
    onChangeValue() {
      this.sectionData.assetValue < 0 ? this.sectionData.assetValue *= -1 : this.sectionData.assetValue
      this.calculatePayments()
    },

    onChangeType() {
      this.calculatePayments();
      this.calculateAssetValueByPeriod();
    },

    calculatePayments() {
      if (
        this.sectionData.assetValue == 0 || 
        !this.sectionData.startDate || 
        !this.sectionData.paymentType) 
      {
        return
      }

      const year = moment(this.sectionData.startDate.value).year() - this.bplanStartYear;
      const month = moment(this.sectionData.startDate.value).month()
      const assetValue = Number(this.sectionData.assetValue)
      const assetType = this.sectionData.type
      const emptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
      const debtAmountEmptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
      
      // Запрещам самостоятельное редактирование (если было позволино)
      // Обнуляем данные, т.к они пересчитываются при каждом изменении
      this.dataReadonly = true;
      this.sectionData.values = emptyData;
      this.sectionData.debtAmount = debtAmountEmptyData;
      

      // Пересчитываем стоимость актива во времени
      this.calculateAssetValueByPeriod()

      var paymentPeriods, periodAmount, debtAmount
      
      // в зависимости от выбранных опций заполняем данные
      switch (this.sectionData.paymentType.id) {
        case 0: // единоразовый платеж
          this.$set(this.sectionData.values[year], month, parseInt(this.sectionData.assetValue))
          break;
        case 1: // равномерное на весь период жизни актива
          paymentPeriods = assetType
          debtAmount = assetValue
          periodAmount = assetValue / paymentPeriods
          for (var i = month; i < 12; i++) {
            if (paymentPeriods > 0) {
              debtAmount = debtAmount - periodAmount
              this.$set(this.sectionData.values[year], i, periodAmount)
              this.$set(this.sectionData.debtAmount[year], i, parseFloat(debtAmount.toFixed(0)))
            }
            paymentPeriods--
          }
          for (var y = year + 1; y < 3; y++) {
            for (var k = 0; k < 12; k++) {
              if (paymentPeriods > 0) {
                debtAmount = debtAmount - periodAmount
                this.$set(this.sectionData.values[y], k, periodAmount)
                this.$set(this.sectionData.debtAmount[y], k, parseFloat(debtAmount.toFixed(0)))
              }
              paymentPeriods--
            }
          }     
          break;
        case 2: // равномерное до конца года
          paymentPeriods = (12 - month)
          debtAmount = assetValue
          periodAmount = assetValue / paymentPeriods
          for (var i2 = month; i2 < 12; i2++) {
            if (paymentPeriods > 0) {
              debtAmount = debtAmount - periodAmount
              this.$set(this.sectionData.values[year], i2, periodAmount)
              this.$set(this.sectionData.debtAmount[year], i2, parseFloat(debtAmount.toFixed(0)))
            }
            paymentPeriods--
          }
          for (var y2 = year + 1; y2 < 3; y2++) {
            for (var k2 = 0; k2 < 12; k2++) {
              if (paymentPeriods > 0) {
                debtAmount = debtAmount - periodAmount
                this.$set(this.sectionData.values[y2], k2, periodAmount)
                this.$set(this.sectionData.debtAmount[y], k2, parseFloat(debtAmount.toFixed(0)))
              }
              paymentPeriods--
            }
          }    
          break;
        case 3: // равномерное на 12 мес
          paymentPeriods = 12
          debtAmount = assetValue
          periodAmount = assetValue / paymentPeriods
          for (var i3 = month; i3 < 12; i3++) {
            if (paymentPeriods > 0) {
              debtAmount = debtAmount - periodAmount
              this.$set(this.sectionData.values[year], i3, periodAmount)
              this.$set(this.sectionData.debtAmount[year], i3, parseFloat(debtAmount.toFixed(0)))
            }
            paymentPeriods--
          }
          for (var y3 = year + 1; y3 < 3; y3++) {
            for (var k3 = 0; k3 < 12; k3++) {
              if (paymentPeriods > 0) {
                debtAmount = debtAmount - periodAmount
                this.$set(this.sectionData.values[y3], k3, periodAmount)
                this.$set(this.sectionData.debtAmount[y3], k3, parseFloat(debtAmount.toFixed(0)))
              }
              paymentPeriods--
            }
          }    
        break;
        case 4: // равномерное на 24 мес
          paymentPeriods = 24
          debtAmount = assetValue
          periodAmount = assetValue / paymentPeriods
          for (var i4 = month; i4 < 12; i4++) {
            if (paymentPeriods > 0) {
              debtAmount = debtAmount - periodAmount
              this.$set(this.sectionData.values[year], i4, periodAmount)
              this.$set(this.sectionData.debtAmount[year], i4, parseFloat(debtAmount.toFixed(0)))
            }
            paymentPeriods--
          }
          for (var y4 = year + 1; y4 < 3; y4++) {
            for (var k4 = 0; k4 < 12; k4++) {
              if (paymentPeriods > 0) {
                debtAmount = debtAmount - periodAmount
                this.$set(this.sectionData.values[y4], k4, periodAmount)
                this.$set(this.sectionData.debtAmount[y4], k4, parseFloat(debtAmount.toFixed(0)))
              }
              paymentPeriods--
            }
          }    
          break;
        case 5: // равномерное на 36 мес
          paymentPeriods = 36
          debtAmount = assetValue
          periodAmount = assetValue / paymentPeriods
          for (var i5 = month; i5 < 12; i5++) {
            if (paymentPeriods > 0) {
              debtAmount = debtAmount - periodAmount
              this.$set(this.sectionData.values[year], i5, periodAmount)
              this.$set(this.sectionData.debtAmount[year], i5, parseFloat(debtAmount.toFixed(0)))
            }
            paymentPeriods--
          }
          for (var y5 = year + 1; y5 < 3; y5++) {
            for (var k5 = 0; k5 < 12; k5++) {
              if (paymentPeriods > 0) {
                debtAmount = debtAmount - periodAmount
                this.$set(this.sectionData.values[y5], k5, periodAmount)
                this.$set(this.sectionData.debtAmount[y5], k5, parseFloat(debtAmount.toFixed(0)))
              }
              paymentPeriods--
            }
          }    
          break;
        case 6: // разрешить пользователю указать данные самостоятельно
          this.dataReadonly = false
          break;
      
        default:
          break;
      }
    },

    calculateAssetValueByPeriod() {
      // Определяем год покупки актива
      const year = moment(this.sectionData.startDate.value).year() - this.bplanStartYear;
      // Опеределяем месяц покупки актива
      const month = moment(this.sectionData.startDate.value).month();
      // Берем начальную стоимость актива
      var assetValue = parseFloat(this.sectionData.assetValue);
      // Уставнавливаем нулевые данные
      const emptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
      const depreciationEmptyData = {
        0: [null, null, null, null, null, null, null, null, null, null, null, null],
        1: [null, null, null, null, null, null, null, null, null, null, null, null],
        2: [null, null, null, null, null, null, null, null, null, null, null, null],
      };
     
     // Обнуляем денные для последующей записи
      this.sectionData.assetCurrentValue = emptyData
      this.sectionData.depreciationValues = depreciationEmptyData
     
     // устанавливаем количество периодов амортизации
      var depPeriods = this.sectionData.type + 1
     
     // устанавливаем величину амортизации
      const depAmount = assetValue / this.sectionData.type

      for (var i = month; i < 12; i++) {
        if (depPeriods > 0 && assetValue >= 0) {
          this.$set(this.sectionData.assetCurrentValue[year], i, parseInt(assetValue));
          // Уставнавливаем величину аммортизации, на следующий месяц после покупки актива
          if (i > month) {
            this.$set(this.sectionData.depreciationValues[year], i, parseInt(depAmount));
          }
          // уменьшаем стоимость актива на величину аммортизации
          assetValue = assetValue - depAmount
        }
        depPeriods--
      }
      for (var y = year + 1; y < 3; y++) {
        for (var k = 0; k < 12; k++) {
          if (depPeriods > 0 && assetValue >= 0) {
            this.$set(this.sectionData.assetCurrentValue[y], k, parseInt(assetValue))
            this.$set(this.sectionData.depreciationValues[y], k, parseInt(depAmount))
            // уменьшаем стоимость актива на величину аммортизации
            assetValue = assetValue - depAmount
          }
          depPeriods--
        }
      }     
    }, 

    async saveData() {
      this.loadData = true

      if (
        !this.sectionData.title         || 
        !this.sectionData.type          || 
        !this.sectionData.assetValue    || 
        !this.sectionData.startDate     || 
        !this.sectionData.paymentType
      ) {
        this.loadData = false
        return
      }

      var payload = this.$store.getters.getAssetToEdit

      try {
        if (payload.id) {
          await this.$store.dispatch('updateAssetDocument', payload)
        } else {
          await this.$store.dispatch('createAssetDocument', payload)
        }
      } catch (error) {
        window.console.log('Unable to create asset document', error)
      }
      
      this.loadData = false
      this.closeModal()
    },

    closeModal() {
      this.showModal = false
    },

    deleteItem() {
      const payload = this.$store.getters.getAssetToEdit

      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить данный расход?', {
        title: 'Подтвердите удаление',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, удалить',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(async () => {
        await this.$store.dispatch('deleteAssetDocument', payload)
        this.closeModal()
      })
      .catch(err => {
          // An error occurred
          window.console.log('unable to delete asset document: ', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.income-title {
  line-height: 90%; 
  margin-top: 2px; 
  margin-bottom: 0.5rem;
}

.income-option {
  padding: 0 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(14, 187, 245, 0.10);
  }
  &.selected {
    background: rgba(14, 187, 245, 0.10);
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  span {
    display: none;
  }
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
    span {
      display: inline;
    }
  }
}

</style>